<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:w-5/5 space-y-7">
      <div :class="'card content content-' + document.id">
        <div class="rounded-t-lg w-full" v-if="document.type === 'news' && document.thumbnail">
          <img :src="document.thumbnail ? document.thumbnail.url : 'https://static.bcdcnt.net/assets/images/blog/img-5.jpg'"
               :alt="document.title" class="w-full object-cover">
        </div>
        <div class="p-4">
          <h1 class="lg:text-3xl text-2xl font-semibold mb-6">{{ document.title }}</h1>
          <div v-if="document.type === 'image' && document.thumbnail">
            <div class="card">
              <img :src="document.thumbnail.url" :alt="document.title" class="cover w-full h-full">
            </div>
          </div>
          <div v-if="document.type === 'audio' && document.file">
            <Embed :type="document.file.type"
                   :src="document.file.type === 'audio' ? document.file.audio_url : document.file.video_url"
                   v-if="document.file"/>
          </div>
          <div class="mt-4" v-html="document.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {
  canManageDocument,
  changePageTitle,
  logActivity,
  timeago,
  convertOembed,
  numberFormat
} from "../../core/services/utils.service";
import Embed from "../Embed";

export default {
  name: "DocumentDetail",
  components: {
    Embed
  },
  data() {
    return {
      id: null,
      commentId: null,
      document: {
        id: 0,
        title: "",
        content: "",
        created_at: "",
        artists: {
          data: []
        }
      }
    }
  },
  methods: {
    loadDocument() {
      let query = `query($id: ID!) {
        document(id: $id) {
          id
          title
          slug
          content
          type
          views
          created_at
          uploader {
            id
            username
            avatar {
              url
            }
          }
          thumbnail {
            url
          }
          file {
            audio_url
            video_url
            type
          }
          artists(first: 100) {
            data {
              title
              slug
              avatar {
                url
              }
            }
          }
          composers(first: 100) {
            data {
              title
              slug
              avatar {
                url
              }
            }
          }
          poets(first: 100) {
            data {
              title
              slug
              avatar {
                url
              }
            }
          }
          recomposers(first: 100) {
            data {
              title
              slug
              avatar {
                url
              }
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.document) {
              this.document = data.data.document;
              this.document.content = convertOembed(this.document.content);
              changePageTitle(this.document.title);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    reRender() {
      this.loadDocument();
      logActivity("view", "document", this.id);
    },
    timeago(x) {
      return timeago(x);
    },
    numberFormat(x) {
      return numberFormat(x);
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  },
  computed: {
    canManageDocument() {
      return canManageDocument();
    }
  }
}
</script>
